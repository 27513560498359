let url = "";

if (process.env.NODE_ENV === 'development') {
    url = "http://127.0.0.1:8000";
} else {
    url = "https://api.agrotec.laonlabs.com";
}

export const Constants = {
    url: url,
    baseUrl: url + "/api/admin"
};