import axios from "axios";
import { Constants } from "../utils/Constants";

export function getAniversariosDia(data) {
	return axios.post(`${Constants.baseUrl}/dashboard/aniversarios-dia`, data);
}

export function getDocumentosVencer(data) {
	return axios.post(`${Constants.baseUrl}/dashboard/documentos-vencer`, data);
}

export function getDocumentosVencidos(data) {
	return axios.post(`${Constants.baseUrl}/dashboard/documentos-vencidos`, data);
}

export function getLimiteCreditoVencer(data) {
	return axios.post(`${Constants.baseUrl}/dashboard/limite-credito-vencer`, data);
} 