import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "./dashboard/DashboardPage";
import { LayoutSplashScreen } from "../../../_metronic";

const AdminPage = lazy(() => import("./admin/AdminPage"));
const GrupoUsuarioPage = lazy(() => import("./grupo-usuario/GrupoUsuarioPage"));
const ClientePage = lazy(() => import("./cliente/ClientePage"));
const InstituicaoPage = lazy(() => import("./instituicao-financeira/InstituicaoPage"));
const DocumentoPage = lazy(() => import("./documento/DocumentoPage"));
const GrupoEconomicoPage = lazy(() => import("./grupo-economico/GrupoEconomicoPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/dashboard" />

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/grupo-usuario" component={GrupoUsuarioPage} />
                <Route path="/cliente" component={ClientePage} />
                <Route path="/instituicao" component={InstituicaoPage} />
                <Route path="/tipo-documento" component={DocumentoPage} />
                <Route path="/grupo-economico" component={GrupoEconomicoPage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
