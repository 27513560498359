import React, { Component } from "react";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { getAniversariosDia, getDocumentosVencer, getDocumentosVencidos, getLimiteCreditoVencer } from "../../../crud/dashboard.crud";
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import BetterBox from "../../../components/BetterBox";
import { Column } from "primereact/column";
import moment from "moment";
import BetterDataTable from "../../../components/BetterDataTable";
import { Link } from "react-router-dom";

export default class DashboardPage extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			error: false,
			data: null,
		};
	}

	getClienteNome = (obj) => {
		return obj?.cli_name ?? "";
	};

	getClienteTelefone = (obj) => {
		return obj?.notion?.results[0].properties?.Telefone?.phone_number ?? "N/A";
	};

	columnOpcoesCrudCliente = (rowData, column) => {
		return (
			<Link to={`cliente/edit/${rowData.cli_id_cliente_notion}`} className="btn btn-primary btn-table-action">
				<i className="fas fa-eye"></i>
			</Link>
		)
	};

	render() {
		return (
			<BetterCircularProgress loading={this.state.loading}>
				<BetterBox title="Aniversários do dia" actions={null}>
					<BetterDataTable fetchEvent={getAniversariosDia} crudButtons={false} crudUrl={"dashboard"} idRow={`id`} deleteHandler={null}>
						<Column field={(obj) => this.getClienteNome(obj)} header="Cliente" />
						<Column field={(obj) => moment(obj.cli_data_nascimento).format('DD/MM/YYYY')} header="Data de nascimento" />
						{/* <Column field={(obj) => this.getClienteTelefone(obj)} header="Telefone" /> */}
						<Column header="Opções" body={this.columnOpcoesCrudCliente} style={{ width: "190px" }} />
					</BetterDataTable>
				</BetterBox>

				<BetterBox title="Documentos a vencer nos próximos 30 dias" actions={null}>
					<BetterDataTable fetchEvent={getDocumentosVencer} crudButtons={false} crudUrl={"dashboard"} idRow={`id`} deleteHandler={null}>
						<Column field={(obj) => this.getClienteNome(obj)} header="Cliente" />
						<Column field='documento.tdo_tipo' header="Tipo de documento" />
						<Column field='cdo_nome_documento' header="Documento" />
						<Column field={(obj) => moment(obj.cdo_validade).format('DD/MM/YYYY')} header="Data de vencimento" />
						{/* <Column field={(obj) => this.getClienteTelefone(obj)} header="Telefone" /> */}
						<Column header="Opções" body={this.columnOpcoesCrudCliente} style={{ width: "190px" }} />
					</BetterDataTable>
				</BetterBox>

				<BetterBox title="Documentos vencidos" actions={null}>
					<BetterDataTable fetchEvent={getDocumentosVencidos} crudButtons={false} crudUrl={"dashboard"} idRow={`id`} deleteHandler={null}>
						<Column field={(obj) => this.getClienteNome(obj)} header="Cliente" />
						<Column field='documento.tdo_tipo' header="Tipo de documento" />
						<Column field='cdo_nome_documento' header="Documento" />
						<Column field={(obj) => moment(obj.cdo_validade).format('DD/MM/YYYY')} header="Data de vencimento" />
						{/* <Column field={(obj) => this.getClienteTelefone(obj)} header="Telefone" /> */}
						<Column header="Opções" body={this.columnOpcoesCrudCliente} style={{ width: "190px" }} />
					</BetterDataTable>
				</BetterBox>

				<BetterBox title="Limite de crédito a vencer nos próximos 60 dias" actions={null}>
					<BetterDataTable fetchEvent={getLimiteCreditoVencer} crudButtons={false} crudUrl={"dashboard"} idRow={`id`} deleteHandler={null}>
						<Column field={(obj) => this.getClienteNome(obj)} header="Cliente" />
						<Column field='clc_prazo' header="Prazo" />
						<Column field='clc_instituicao' header="Instituição financeira" />
						<Column field={(obj) => moment(obj.clc_vencimento_limite).format('DD/MM/YYYY')} header="Vencimento" />
						{/* <Column field={(obj) => this.getClienteTelefone(obj)} header="Telefone" /> */}
						<Column header="Opções" body={this.columnOpcoesCrudCliente} style={{ width: "190px" }} />
					</BetterDataTable>
				</BetterBox>
			</BetterCircularProgress>
		);
	}
}
