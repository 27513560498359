import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Column } from "primereact/column";
import Swal from "sweetalert2";

export default class BetterDataTable extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			globalFilter: null,
			sortOrder: null,
			sortField: null,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.onPage(this.state);
	}

	doGlobalFiltering(value) {
		this.setState({ globalFilter: value });

		setTimeout(() => {
			let event = { ...this.state, globalFilter: value };
			this.onPage(event);
		}, 350);
	}

	refreshTable() {
		this.setState({ sortOrder: null, sortField: null }, () => this.onPage(this.state));
	}

	onPage = (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true,
		});

		this.props.fetchEvent({ ...event, sortOrder: this.state.sortOrder, sortField: this.state.sortField }).then((res) => {
			if (res?.status == 200) {
				this.setState({
					...this.state,
					data: res.data.data.data,
					totalRecords: res.data.data.total,
					first: res.data.data.from - 1,
					last: res.data.data.to - 1,
					loading: false,
				});
			}
		});
	};

	handleDelete = (id) => {
		Swal.fire({
			title: "Atenção!",
			text: "Tem certeza que deseja desativar / excluir esse registro?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			cancelButtonText: "Não",
			confirmButtonText: "Sim",
		}).then((result) => {
			if (result.value) {
				if (this.props.deleteHandler) {
					this.setState({ loading: true });

					this.props
						.deleteHandler(id)
						.then(() => {
							this.refreshTable();

							Swal.fire("Tudo certo!", "Registro alterado com sucesso!", "success");
							this.setState({ loading: false });
						})
						.catch((err) => {
							Swal.fire("Ops!", "Houve um problema ao remover o registro. Entre em contato com o suporte.", "error");
							this.setState({ loading: false });
						});
				} else {
					alert("Nenhum método de remoção definido.");
				}
			}
		});
	};

	columnOpcoesCrud = (rowData, column) => {
		let idRow = this.props.idRow ? this.props.idRow : "id";

		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					{this.props.moreOptions ? this.props.moreOptions(rowData) : null}

					{!this.props.noEdit ? (
						<Link to={`${this.props.crudUrl}/edit/${rowData[idRow]}`} className="btn btn-success btn-table-action">
							<i className="fa fa-edit"></i>
						</Link>
					) : null}

					{!this.props.noShow ? (
						<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action">
							<i className="fas fa-eye"></i>
						</Link>
					) : null}

					{!this.props.noDelete ? (
						<button onClick={() => this.handleDelete(rowData[idRow])} className="btn btn-danger btn-table-action">
							<i className="fas fa-trash-alt"></i>
						</button>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	};

	sortTable = (e) => {
		if (this.props.onSort) {
			this.setState({ sortOrder: e.sortOrder, sortField: e.sortField }, () => this.onPage(this.state));
		}
	};

	// export() {
	//     this.dt.exportCSV();
	// }

	render() {
		const header = (
			<div style={{ float: "right" }}>
				<InputText
					className="tableHeaderItem"
					style={{ marginRight: "5px" }}
					type="search"
					onInput={(e) => {
						this.setState({ globalFilter: e.target.value });
						this.doGlobalFiltering(e.target.value);
					}}
					placeholder="Pesquise..."
					size="30"
				/>
				{/* <Button className="tableHeaderItem" type="button" style={{ marginRight: '5px', marginLeft: '5px' }} icon="fas fa-file-csv" iconPos="left" label="CSV" onClick={() => this.export()} /> */}
				<Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
			</div>
		);

		return (
			<DataTable ref={(el) => (this.dt = el)} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} globalFilter={this.state.globalFilter} responsive={true} totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!" sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={(e) => this.sortTable(e)}>
				{this.props.children}
				{this.props.crudButtons ? <Column header="Opções" body={this.columnOpcoesCrud} style={{ width: "190px" }} /> : null}

				{this.props.columnOpcoes ? <Column header="Opções" body={this.props.columnOpcoesBody} style={{ width: "190px" }} /> : null}
			</DataTable>
		);
	}
}
