export default {
	header: {
		self: {},
		items: [],
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot",
			},
			{
				title: "Clientes",
				root: true,
				icon: "fas fa-users",
				page: "cliente",
				bullet: "dot",
			},
			{
				title: "Grupos econômicos",
				root: true,
				icon: "fas fa-users",
				page: "grupo-economico",
				bullet: "dot",
			},
			{
				title: "Tipos de Documentos",
				root: true,
				icon: "fas fa-file-signature",
				page: "tipo-documento",
				bullet: "dot",
			},
			{
				title: "Instituições Financeiras",
				root: true,
				icon: "fas fa-dollar-sign",
				page: "instituicao",
				bullet: "dot",
			},
			{
				title: "Acessos",
				root: true,
				bullet: "dot",
				icon: "fas fa-user-shield",
				submenu: [
					{
						title: "Administradores",
						bullet: "dot",
						page: "admin",
					},
					{
						title: "Grupo de usuários",
						bullet: "dot",
						page: "grupo-usuario",
					},
				],
			},
		],
	},
};
